import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreloadAllModules } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: '', redirectTo: 'he', pathMatch: 'full' },
  { path: 'he', component: HomeComponent, pathMatch: 'full' },
  { path: 'en', component: HomeComponent, pathMatch: 'full' },
  { path: '', loadChildren: () => import('./external-pages.module').then(m => m.ExternalPagesModule) },
  { path: ':lang/account', loadChildren: () => import('./account-pages.module').then(m => m.AccountPagesModule) },
  { path: '', loadChildren: () => import('./internal-pages.module').then(m => m.InternalPagesModule) },
  { path: '**', redirectTo: 'he/404' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
