import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(
    private translate: TranslocoService,
    private route: ActivatedRoute,
    private meta: Meta
    ) {
      this.language = this.route.snapshot.url[0].path;
      this.translate.setActiveLang(this.language);
      for (const lang of ['he', 'en']) {
        this.translate.load(lang).subscribe();
      }
      if (this.language === 'en') {
        this.translate.selectTranslation('en').subscribe(translation => {
          console.log('description: ' + this.translate.translate('home.description'));
          this.meta.updateTag({ name: 'description', content: this.translate.translate('home.description') });
        });
      }
  }

  language: string;
}
