<div class="container" fxLayout="column">
    <div class="background-arch">
      <img src="assets/svg/home-arc.svg" alt="Light blue arch">
    </div>
    <div class="logo" fxLayout="row" fxLayoutAlign="center">
      <img src="assets/svg/krovim-logo-vertical.svg" alt="Krovim Logo">
    </div>
    <div class="signup-button-container" fxLayout="row" fxLayoutAlign="center">
      <div class="signup-button" fxFlex="80">
        <button *ngIf="language === 'he'" mat-flat-button routerLink="signup" class="material background">
          {{ 'home.signup' | transloco }}
        </button>
        <button *ngIf="language === 'en'" mat-flat-button [routerLink]="['/', 'en', 'about', 'what-is-this']" class="material background">
          {{ 'home.signup' | transloco }}
        </button>
      </div>
    </div>
    <div class="language-link" fxLayout="row" fxLayoutAlign="center">
      <a *ngIf="language === 'he'" class="text__normal" routerLink="/en/about/what-is-this">{{ 'home.language' | transloco }}</a>
      <a *ngIf="language === 'en'" class="text__normal" routerLink="/he/signup">{{ 'home.language' | transloco }}</a>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="5%">
      <div fxFlex="50">
        <div fxLayout="row" fxLayoutAlign="center">
          <div fxFlex="70">
            <img src="assets/svg/bird-hi.svg" alt="Bird waving her hand" fxFlexFill>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <div fxFlex="100">
            <img class="bench" src="assets/svg/home-bench.svg" alt="Bird on bench">
          </div>
        </div>
      </div>
      <div fxFlex="50">
        <div fxLayout="row" fxLayoutAlign="center">
          <div fxFlex="90" dir="rtl">
            <img src="assets/svg/home-goldberg.svg" alt="Quote from Leah Goldberg">
          </div>
        </div>
      </div>
    </div>
</div>
<div class="text__smaller center copyright">{{ 'home.copyright' | transloco }}</div>